//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import { mapState, mapActions } from "vuex";
//import Cart from "@/components/cart";

//import axios from "axios";



export default {
  data: function () {
    return {
        
      state_config_codesys: 'LOADING', // LOADING, LOAD_ERROR, LOAD_OK, UPDATING,  UPDATE_OK, UPDATE_ERROR
      state_config_codesys_error_message:'',
      
      truck_selected:null,
      timer: null,
      last_update: null,
      filter: {
        faena:{faenaid:"",name:"--TODAS--"},
        faenaid:'',
      },
      select_faenas:[],
      camiones: [],
      show:false,
      show_events: false,
      show_config: false,
      events_last: null,
      //trucks: [],
      status_trucks: {},
      
      vpn_port: 'radio-1',
    }
  },

  //components: {
  //  Cart
  //},

  created() {
    console.log('create -> [Trucks]');

    this.$store.state.ts.loader.show = true;

    //disconnected_time: null,

    this.$axios.all( [
      this.$axios.get('/api/truck/'),
      this.$axios.get('/api/statustruck/'),
      this.$axios.get('/api/faena/'),
    ])
    .then(this.$axios.spread((truck_resp, status_resp,faena_resp)  => {
      this.$util.log(truck_resp);
      this.$util.log(status_resp);

      this.$store.state.trucks = truck_resp.data;

      this.$store.state.faenas = faena_resp.data;

      this.select_faenas = faena_resp.data;
      this.select_faenas.unshift({faenaid:"",name:"--TODAS--"});
      //this.select_faenas = [{faenaid:"",name:"--TODAS--"}];
      //console.log(this.select_faenas);

      this.status_trucks = status_resp.data.trucks;
      this.last_update = status_resp.data.stamp;
      
      this.$store.state.ts.loader.show = false;
      this.show=true;

      this.timer= setInterval(this.update, 30000);


    }))
    .catch((truck_err, status_err, faena_err) => {
      console.log(truck_err);
      console.log(status_err);
      console.log(faena_err);
    });

  },

  beforeDestroy() {
    clearTimeout(this.timer);
  },

  computed: {


  },
  
  methods: {


    formatMinutes(m) {

      if (m<0) {
        return 'Nunca se ha conectado';
      }
      if (m<60) {
        return 'Desconectado '+m+"[min]";
      }
      if ((m>=60) && (m<60*24)) {
        return 'Desconectado '+(m/60).toFixed(2)+'[horas]';
      }
      else {
        return 'Desconectado '+(m/(60*24)).toFixed(2)+'[días]';
      }

    },
  

    onChangeFaena() {
      console.log(this.filter.faenaid);
      
    },

    onClickGraficos: function(truck) {
      truck.faena = this.select_faenas.find(e => e.faenaid === truck.faenaid).name;//se agega en nombre de la faena para mostrar en graficos
      console.log(truck);
      this.$store.state.ts.loader.show = true;
      this.$store.state.truck = truck;
      this.$router.push("/main/camion_graficos");
      //this.$emit('TSLoader', {show:false,text:''});
    },

    onClickEBLAS: function(truck) {
      console.log(truck);
      
      //window.open("http://10.8.0.242:8080/webvisu.htm","popup", "width=1200, height=900");
      //truck.faena = this.select_faenas.find(e => e.faenaid === truck.faenaid).name;
      //this.$store.state.ts.loader.show = true;
      this.$store.state.truck = truck;
      this.$router.push("/main/camion_eblas");
    },


    onClickEvents: function(truck) {
      this.$util.log(truck);

      this.show_events= false;
      this.$store.state.ts.loader.show = true;
      this.$axios.all( [
        this.$axios.get('/api/eventlast/', { params: {truckid: truck.truckid} } )     
      ])
      .then(this.$axios.spread((eventlast_resp)  => {
        
        this.$util.log(eventlast_resp);
        
        this.events_last = eventlast_resp.data;

        this.show_events=true;
        this.$store.state.ts.loader.show = false;
        this.show_events=true;

        this.events_last.forEach(function(v,idx,arr) {
            if (! ('count' in v)) {
                arr[idx]['count']= 1
            }
        }.bind(this));

        
      }))
      .catch((eventlast_err) => {
        this.$util.log(eventlast_err);
        this.$store.state.ts.loader.show = false;

      });
    },
    
    


    onClickUpdateEvent: function(event) {
      console.log(event);

      event.check_email = this.$store.state.my_user.email; 

      this.$store.state.ts.loader.show = true;
      this.$axios.patch('/api/eventlast/',event).then(resp => {
        console.log(resp);
      
        // Actualizo listado
        this.$axios.all( [
          this.$axios.get('/api/eventlast/', { params: {truckid: event.truckid} } ),
          this.$axios.get('/api/statustruck/'),    
        ])
        .then(this.$axios.spread((eventlast_resp,status_resp)  => {
          
          //this.$util.log(eventlast_resp);
          
          this.events_last = eventlast_resp.data;
          this.status_trucks = status_resp.data.trucks;
          this.last_update = status_resp.data.stamp;

          //this.show_events=true;
          this.$store.state.ts.loader.show = false;
          
          //this.show_events=true;
          
        }))
        .catch((eventlast_err,status_err) => {
          this.$util.log(eventlast_err);
          this.$util.log(status_err);
          this.$store.state.ts.loader.show = false;

        });


      })
      .catch((resp_err) => {
        this.$util.log(resp_err);
        this.$store.state.ts.loader.show = false;
      });

    },

    
    onClickConfig: function(truck) {
        // Configuracion cdesys
        this.$util.log(truck);
        this.truck_selected = truck;
        this.show_config = true;
        this.state_config_codesys= 'LOADING';
        
        // Primero va a buscar la configuracion a la PI
        
        this.$axios.all( [
          this.$axios.get('/api/codesysport/', { params: { truckid: truck.truckid } } ),  
        ])
        .then(this.$axios.spread((codesysport_resp)  => {
          
          this.$util.log(codesysport_resp);
          this.vpn_port = codesysport_resp.data.port;
          this.state_config_codesys= 'LOAD_OK';
          
        }))
        .catch( (codesysport_err) => {
          this.$util.log(codesysport_err);
          
          if (codesysport_err.response.status==400) {
              console.log(codesysport_err.response.data.errors[0]);
              this.state_config_codesys_error_message = codesysport_err.response.data.errors[0];
          }
          else {
              this.state_config_codesys_error_message= 'No se pudo leer la configuración del camión';
          }
          
          this.state_config_codesys= 'LOAD_ERROR';
          
          
        });
        

      /*
      })
      .catch((resp_err) => {
        this.$util.log(resp_err);
        this.$store.state.ts.loader.show = false;
      });
      */
      
        
    },
      
    onClickSetCODESYS: function(truck) {
        this.$util.log(truck);
        
        this.$util.log('=============');
        this.$util.log(this.vpn_port);
        
        this.state_config_codesys= 'UPDATING';
        
        this.$axios.patch('/api/codesysport/',{ truckid: truck.truckid, port: this.vpn_port }).then(resp => {
            console.log(resp);
            this.state_config_codesys= 'UPDATE_OK';
        })
        .catch((resp_err) => {
            this.$util.log(resp_err);
            this.state_config_codesys= 'UPDATE_ERROR';
            //this.$store.state.ts.loader.show = false;
        });
        
    },

    
    update() {
      /*
      this.$axios.all( [
        this.$axios.get('/api/setcodesys/'),
      ])
      .then(this.$axios.spread((status_resp)  => {

        this.$util.log(status_resp);
        this.status_trucks = status_resp.data.trucks;
        this.last_update = status_resp.data.stamp;

      }))
      .catch((status_err) => {
       console.log(status_err);
      });
	*/
    }


  }
};
